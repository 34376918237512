var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":300,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mt-4",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("\n      + "+_vm._s(_vm.$t('title'))+"\n    ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',{attrs:{"min-width":"300"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('title'))+" ")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-text-field',{staticClass:"px-4",attrs:{"label":""},model:{value:(_setup.desc),callback:function ($$v) {_setup.desc=$$v},expression:"desc"}}),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"variant":"text"},on:{"click":function($event){dialog.value = false}}},[_vm._v(" Cancel ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","variant":"text","loading":_setup.loading},on:{"~click":function($event){return (() => {
              _setup.add({
                input: {
                  description: _setup.desc,
                  type: _setup.props.type,
                  entityIntId: _setup.props.entityIntId,
                  entityUuidId: _setup.props.entityUuidId,
                },
              }).then((v) => {
                dialog.value = false
                _setup.desc = ''
                _vm.$emit('done', v)
              })
            }).apply(null, arguments)}}},[_vm._v("\n          Save\n        ")])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }